import React, { Component } from "react"
import {Link, graphql } from "gatsby"
import Layout from '../layout/layout'
import './blog-list-template.scss'
import Seo from '../components/seo'

class PostList extends Component {
  render() {
    const data = this.props.data
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    // console.log("isLast: " + numPages);
    return (
      <Layout>
        <Seo postTitle="Blog " />
        <div className="posts_section"> 
            <h1>Posts</h1>
        {data.allWordpressPost.edges.map(({ node }) => (
          <div className="post_item" key={node.slug}>
            <Link to={"/blog/" + node.slug}>
               <CheckImage featured_media={node.featuredImage}/>
               </Link>
            <div className="post_item_content">
              <Link to={"/blog/" + node.slug}>
                <h2 className="post_item_header">{node.title}</h2>
              </Link>
              <h3>{node.date}</h3>
            <div  dangerouslySetInnerHTML={{ __html: node.excerpt}}></div>
            <div className="posts_continue"><Link to={"/blog/" + node.slug}>Continue Reading .....</Link></div>
          </div>
          </div>
        ))}
        </div>
          <Pagenation 
          prevPage={prevPage}
          numPages={numPages}
          nextPage={nextPage}
          isFirst={isFirst}
          isLast={isLast}  
          />
        </Layout>
    )
  }
} 
export default PostList


// does the number pagenation for the blog roll pages.
function Pagenation(props) {
  return(
  <div className="page_nagination">
    {!props.isFirst && (
      <Link to={"/blog/" + props.prevPage} rel="prev">
        &#60;&#60;
      </Link>
    )}

    {Array.from({ length: props.numPages }, (_, i) => (
      <Link className='active' key={`pagination-number${i + 1}`} to={`/blog/${i === 0 ? "" : i + 1}`}>
        {i + 1}
      </Link>
    ))}

    {!props.isLast && (
      <Link to={"/blog/" + props.nextPage} rel="next">
        &#62;&#62;
      </Link>
    )}
  </div>
  )}



  // checks whether the featured image of the blog post is there.
function CheckImage(props) {
  // console.log("hello featured image")
  // console.log(props)
if( props.featured_media.node != null){
  return(
  <picture>
    <img alt="" srcSet={props.featured_media.node.localFile.childImageSharp.fluid.srcSet} />
  </picture>
  )
}else{
  return(
    <picture>
      {/* need to remove  */}
      <img  srcSet="https://via.placeholder.com/400x200" alt=""/>
    </picture>
    )}
}



// Queries
export const pageQuery = graphql`
query ($skip: Int!, $limit: Int!) {
  allWordpressPost(sort: {fields: date, order: DESC}, skip: $skip, limit: $limit) {
    edges {
      node {
        id
        date(formatString: "dddd DD MMMM YYYY")
        title
        excerpt
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
}

`
